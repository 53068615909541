import * as React from "react"
import { Row, Col } from "react-bootstrap"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")
SwiperCore.use([Navigation, Pagination, Autoplay])

const TestimonialsComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        testimonials {
          title
          description
          content
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Row>
        <Col>
          <div className="mb-4">
            <div className="title">
              <h3 className="gradient-text">Depoimentos</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination
            loop
            autoplay={{ delay: 15000 }}
            className="testimonial-slider"
          >
            {data.api.testimonials.map((testimonial, i) => {
              return (
                <SwiperSlide key={`testimonial-${i}`} className="testimonial">
                  <Row className="justify-content-center align-items-center mb-3 mx-2 mx-md-0 text-center text-md-left">
                    <Col md={2}>
                      <GatsbyImage
                        image={
                          testimonial.image.imageFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={8}>
                      <h5 className="mb-0">{testimonial.title}</h5>
                      <p className="mb-0">{testimonial.description}</p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mx-2 mx-md-0">
                    <Col md={10} className="paragraph">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: testimonial.content,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Col>
      </Row>
    </>
  )
}

export default TestimonialsComponent
